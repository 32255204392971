import clsx from 'clsx'
import {useLayout} from '../../core'
import {Footer} from './Footer'
import {toAbsoluteUrl} from '../../../helpers'

const FooterWrapper = () => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div className='app-footer' id='kt_app_footer'>
      {config.app.footer.containerClass ? (
        <>
          <div
            className={clsx(
              'app-container',
              config.app.footer.container === 'fixed' ? 'container-xxl' : 'container-fluid',
              config.app.footer.containerClass
            )}
          >
            <Footer />
          </div>
          <img
            className='px-2 mr-2 bg-white rounded-lg'
            style={{width:'7%', height:'auto'}}
            src={toAbsoluteUrl('/media/logos/dygistechLogo.png')}
            alt='dygistech-logo'
          />
        </>
      ) : (
        <>
          <Footer />
          <img
            className='px-2 mr-2 bg-white rounded-lg'
            style={{width:'7%', height:'auto'}}
            src={toAbsoluteUrl('/media/logos/dygistechLogo.png')}
            alt='dygistech-logo'
          />
        </>
      )}
    </div>
  )
}

export {FooterWrapper}
